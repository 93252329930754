import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Banner from "components/banner"
import Button from 'components/button'

import ExtravertLogo from '../images/extravert-logo.png'
import Heinz from '../images/heinz.png'
import Store from '../images/store.png'

import SEO from "components/seo"

export default ({ data }) => {
    const { service } = data.siteFields

    const {
        featuredImage,
        title,
        slug,
        content,
        serviceFields: {
            tagline,
            intro,
            stats = []
        }
    } = service

    const excerpt = (content).replace(/(<([^>]+)>)/ig, "").slice(0, 160)

    const _stats = stats || []

    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 500

    const extravertLogo = (() => {
        switch (slug) {
            case 'shopper-marketing':
            case 'digital-marketing-communications':
            case 'experiential':
                return ExtravertLogo
        }

        return null
    })()

    return (
        <>
            <SEO title={title} description={excerpt} image={featuredImage.mediaItemUrl} />
            <div className="service">
                <div className="container">
                    <div className="service-single">
                        <div className='service-single-hero'>
                            <div className='service-single-hero-text'>
                                {!isMobile ?
                                    <Button url='/services' label="Back to all" showArrow="left" />
                                    : null}
                                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                                <span />
                                <div className="service-tagline" dangerouslySetInnerHTML={{ __html: tagline }} />
                                <span />
                                <div className="service-intro" dangerouslySetInnerHTML={{ __html: intro }} />
                                <span />
                                {extravertLogo ? <img src={extravertLogo} /> : ''}
                            </div>
                            {featuredImage ? <img src={featuredImage.mediaItemUrl} alt={title} /> : ''}
                        </div>
                    </div>
                </div>
                <div className="service-single">
                    <div className="container">
                        <div className="service-single-tris">
                            <div />
                        </div>
                        <div className="service-single-tris service-single-tris-2">
                            <div />
                        </div>
                        <div className='service-single-content-cont'>
                            <div className='service-single-content'>
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                                <ul className="service-single-stats">
                                    {_stats.map((row, index) => {
                                        const {
                                            stat,
                                            heading
                                        } = row

                                        return (
                                            <li key={index}>
                                                <h2 dangerouslySetInnerHTML={{ __html: stat }} />
                                                <h4 dangerouslySetInnerHTML={{ __html: heading }} />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {
                                slug == 'shopper-marketing' || slug == 'digital-marketing-communications' || slug == 'experiential' ?
                                    <div className="store-images">
                                        <ul className="grid">
                                            <li>
                                                <img className="heinz" src={Heinz} />
                                            </li>
                                            <li>
                                                <img className="store" src={Store} />
                                            </li>
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <Banner
                    context={'services'}
                />
            </div>
        </>
    )
}



export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            service(id: $databaseId, idType: DATABASE_ID) {
                title
                content
                slug
                featuredImage {
                    mediaItemUrl
                }
                serviceFields {
                    tagline
                    intro
                    stats {
                        stat
                        heading
                    }
                }
            }
        }
    }
`
