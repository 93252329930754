import React, { useContext } from "react"
import { MainContext } from "../context/context"
import Button from 'components/button'

export default ({
    title, 
    copy, 
    button, 
    theme = 'darkBlue',
    image,
    context = 'flexible'
}) => {
	const {
        options,
        openModal
    } = useContext(MainContext)
    
    let content = {
        title,
        copy
    }

    let buttonURL = null
    let buttonTitle = 'Scope a project'
    
    if (button) {
        buttonURL = button.url
        buttonTitle = button.title
    }
    
    const imageURL = image ? image.mediaItemUrl : options.defaultBannerImage.sourceUrl

    switch(context){
        case 'blog':
            content = {
                ...options.bannerBlog
            }
            break
        case 'services':
            content = {
                ...options.bannerServices
            }
            break
    }

    return (
        <div className={'banner ' + theme}>
            <div className="container">
                <div>
                    <div className="banner-text">
                        <h2 dangerouslySetInnerHTML={{ __html: content.title || '' }} />
                        <div className="copy" dangerouslySetInnerHTML={{ __html: content.copy || '' }} />
                        <Button 
                            onClick={!buttonURL ? () => openModal({type: 'SCOPE_PROJECT'}) : undefined}
                            url={buttonURL || undefined} 
                            label={buttonTitle} 
                            type="button" 
                            className="white"
                        />
                    </div>
                    <div className="banner-image">
                        <div>
                            <img src={imageURL } alt={title || 'How to be ahead of your market.' } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
